<template>
  <span class="text-color-secondary font-semi-bold" v-if="mode"
    >Ambiente {{ getMode }}
  </span>
</template>

<script>
export default {
  name: "SignatureModeComponent",
  computed: {
    getMode() {
      return this.mode === 2 ? "producción" : "desarrollo";
    }
  },
  props: {
    mode: {
      default: null
    }
  }
};
</script>

<style></style>
