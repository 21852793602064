import { render, staticRenderFns } from "./SignAuthorizationInfo.vue?vue&type=template&id=6791449b&scoped=true"
import script from "./SignAuthorizationInfo.vue?vue&type=script&lang=js"
export * from "./SignAuthorizationInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6791449b",
  null
  
)

export default component.exports