<template>
  <div class="autorization" v-if="accessKey">
    <h5 class="h5 font-bold d-inline-block mr-2">
      Autorización
    </h5>

    <p class="mb-3">
      <span class="font-bold">
        Firmado:
      </span>
      <SignatureModeComponent :mode="signMode" />
    </p>
    <p class="mb-3">
      <span class="font-bold">
        Número:
      </span>
      {{ accessKey }}
    </p>
    <p class="mb-3">
      <span class="font-bold">
        Clave acceso:
      </span>
      {{ accessKey }}
    </p>
  </div>
</template>

<script>
import SignatureModeComponent from "./SignatureMode";

export default {
  name: "SignAuthorizationInfo",
  computed: {},
  components: {
    SignatureModeComponent
  },
  props: {
    accessKey: {
      default: null
    },
    signMode: {
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
